import request from '@/utils/request'
// 获取服务站订单列表，服务站小程序使用
export function WeGetStaOrderPage (query) {
  return request({
    // url: 'Order/WeGetStaOrderPage',
    url: 'Order/SO_GetOrderPage',
    method: 'get',
    params: query
  })
}
// 接收订单，服务站小程序使用
export function WeRecviceOrder (data) {
  return request({
    // url: 'Order/WeRecviceOrder',
    url: 'Order/SO_RecviceOrder',
    method: 'post',
    data
  })
}
// 配送订单，当提供服务后使用，服务站小程序使用
export function WeDeliveryOrder (data) {
  return request({
    // url: 'Order/WeDeliveryOrder',
    url: 'Order/SO_DeliveryOrder',
    method: 'post',
    data
  })
}
// 完成订单，当提供服务后使用，服务站小程序使用
export function SOFinishOrder (data) {
  return request({
    // url: 'Order/WeDeliveryOrder',
    url: 'Order/SO_FinishOrder',
    method: 'post',
    data
  })
}
// 获取服务站订单列表，服务站小程序使用
export function WeGetStaServiceSortPage (query) {
  return request({
    url: 'ProService/WeGetStaServiceSortPage',
    method: 'get',
    params: query
  })
}
// 获取获取养老服务项目列表,服务站小程序使用
export function WeGetProServicetOfStaPage (query) {
  return request({
    url: 'ProService/WeGetProServicetOfStaPage',
    method: 'get',
    params: query
  })
}
// 删除养老服务项目，服务站小程序使用
export function WeDeleteProService (data) {
  return request({
    url: 'ProService/WeDeleteProService',
    method: 'post',
    data
  })
}
// 删除养老服务项目，服务站小程序使用
export function DeleteProService (data) {
  return request({
    url: 'ProService/DeleteProService',
    method: 'post',
    data
  })
}
// 获取服务站订单详情，服务站小程序使用
export function WeGetStaOrder (query) {
  return request({
    url: 'Order/SO_GetStaOrder',
    method: 'get',
    params: query
  })
}
// 获取订单服务项目列表，服务站小程序使用
export function GetOrderServiceList (query) {
  return request({
    url: 'Order/SO_GetOrderServiceList',
    method: 'get',
    params: query
  })
}

export function WeGetStaServiceEvl (query) {
  return request({
    url: 'ServiceEval/WeGetStaServiceEvl',
    method: 'get',
    params: query
  })
}
// 保存养老服务分类，服务站小程序使用
export function WeSaveStaServiceSort (data) {
  return request({
    url: 'ProService/WeSaveStaServiceSort',
    method: 'post',
    data
  })
}
// 保存养老服务项目，服务站小程序使用
export function WeSaveProService (data) {
  return request({
    url: 'ProService/WeSaveProService',
    method: 'post',
    data
  })
}
// 删除养老服务分类，服务站小程序使用
export function WeDeleteStaServiceSort (data) {
  return request({
    url: 'ProService/WeDeleteStaServiceSort',
    method: 'post',
    data
  })
}
// 获取套餐服务记录，服务站小程序使用
export function WeGetStaSPServiceRecPage (query) {
  return request({
    url: 'SPServiceRec/WeGetStaSPServiceRecPage',
    method: 'get',
    params: query
  })
}
// 获取套餐服务记录详情，服务站小程序使用
export function WeGetStaSPServiceRec (query) {
  return request({
    url: 'SPServiceRec/WeGetStaSPServiceRec',
    method: 'get',
    params: query
  })
}
// 保存套餐包服务记录，服务站小程序使用
export function WeSaveSPServiceRec (data) {
  return request({
    url: 'SPServiceRec/WeSaveSPServiceRec',
    method: 'post',
    data
  })
}
// 删除套餐服务记录，服务站小程序使用
export function WeDeleteSPServiceRec (data) {
  return request({
    url: 'SPServiceRec/WeDeleteSPServiceRec',
    method: 'post',
    data
  })
}
// 获取指定类型（级别）的养老服务站列表
export function GetYLOrganList (query) {
  return request({
    url: 'YLOrgan/GetYLOrganList',
    method: 'get',
    params: query
  })
}
// 小程序获取我的用户详情
export function WeGetPersonnel (query) {
  return request({
    url: 'Organ/GetPersonnel',
    method: 'get',
    params: query
  })
}
// 微信绑定
export function BindWechat (data) {
  return request({
    url: 'SOPlat/BindWechat',
    method: 'post',
    data
  })
}
// 微信绑定
export function UnBindWechat (data) {
  return request({
    url: 'SOPlat/UnBindWechat',
    method: 'post',
    data
  })
}